import { ButtonV2, ErrorMessageV3, LinkButton, StatusV2, themeV3, useSafeCallback } from '@atomica.co/components';
import { Code } from '@atomica.co/types';
import { embedIdInPath } from '@atomica.co/utils';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Path, PATH_IDS } from '../../../router/Routes';
import { ContractImportType, FileStatus } from './import-contract-import-type';

interface P {
  baseCode: Code;
  importType: ContractImportType;
  fileStatuses: FileStatus[];
  isHappenErrors: boolean;
  isProcessedExpression: boolean;
  downloadErrorZipFile: () => Promise<void>;
}

export const ImportContractResultComponent: React.FC<P> = React.memo(props => {
  const { baseCode, importType, fileStatuses, isHappenErrors, isProcessedExpression, downloadErrorZipFile } = props;

  const openContractListScreen = useSafeCallback(() => {
    window.open(embedIdInPath(Path.CONTRACT_LIST_V2, PATH_IDS, [baseCode]), '_blank');
  }, [baseCode]);

  const ErrorContent = useMemo(() => {
    return (
      <DownloadWrapper>
        <ErrorMessageV3
          errorMessage={
            'ファイルの取り込みに失敗しました。エラー詳細をダウンロードして内容を確認の上、ファイルを修正してアップロードしてください。'
          }
        />
        <ButtonV2
          type='tertiary'
          size='small'
          label='エラー詳細をダウンロード'
          startIcon={<GetAppRoundedIcon />}
          onClick={downloadErrorZipFile}
        />
      </DownloadWrapper>
    );
  }, [downloadErrorZipFile]);

  const filterStatus = (fileStatuses: FileStatus[], importType: ContractImportType): FileStatus[] => {
    switch (importType) {
      case 'contract':
        return fileStatuses.filter(e => e.key === 'contract' || e.key === 'contractDetails');
      case 'contractUser':
        return fileStatuses.filter(e => e.key === 'contractUsers');
      default:
        return fileStatuses;
    }
  };

  if (!isProcessedExpression) {
    return (
      <>
        <Header>
          <Head>3. 処理結果の確認</Head>
        </Header>
        <Description>ステップ2で、ファイルをアップロードして取り込んでください。</Description>
      </>
    );
  }

  return (
    <ResultArea>
      <Header>
        <Head>3. 処理結果の確認</Head>
        {!isHappenErrors && <LinkButton label='契約一覧' hasIcon onClick={openContractListScreen} />}
      </Header>

      <StatusLabel>処理ステータス</StatusLabel>
      {isHappenErrors && ErrorContent}
      <FileStatusTable>
        <TableHeader>
          <TableHeaderCell>ファイルの種類</TableHeaderCell>
          <TableHeaderCell>処理ステータス</TableHeaderCell>
        </TableHeader>
        <TableBody>
          {filterStatus(fileStatuses, importType).map(({ key, label, status }) => (
            <TableRow key={key}>
              <TableCell>{label}</TableCell>
              <TableCell>
                <StatusV2
                  status={status === 'success' ? 'success' : status === 'failure' ? 'error' : 'warning'}
                  label={status === 'success' ? '成功' : status === 'failure' ? '失敗' : '未処理'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </FileStatusTable>
    </ResultArea>
  );
});

ImportContractResultComponent.displayName = 'ImportContractResultComponent';

const ResultArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing}px;
`;

const DownloadWrapper = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing}px;
`;

const Head = styled.div`
  ${themeV3.mixins.v3.typography.title.xLarge};
  padding: ${themeV3.mixins.v3.spacing / 2}px ${themeV3.mixins.v3.spacing}px;
  display: flex;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${themeV3.mixins.v3.color.border.gray};
  padding: ${themeV3.mixins.v3.spacing}px ${themeV3.mixins.v3.spacing * 2}px ${themeV3.mixins.v3.spacing}px 0;
`;

const FileStatusTable = styled.div`
  border: 1px solid ${themeV3.mixins.v3.color.border.gray};
  border-radius: 4px;
  overflow: hidden;
  width: 40%;
`;

const TableHeader = styled.div`
  display: flex;
  background-color: #f5f5f5;
  border-bottom: 1px solid ${themeV3.mixins.v3.color.border.gray};
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableHeaderCell = styled.div`
  flex: 1;
  padding: 8px 16px;
  font-weight: 500;
  gap: ${themeV3.mixins.v3.spacing}px;
  font-weight: 700;
  font-size: 12px;
  font-style: normal;
`;

const TableRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${themeV3.mixins.v3.color.border.gray};

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  flex: 1 0 0;
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

const StatusLabel = styled.div`
  font-size: ${themeV3.mixins.v3.spacing * 2}px;
  font-style: normal;
  font-weight: 700;
`;

const Description = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  display: flex;
  justify-content: center;
`;
