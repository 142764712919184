import { ButtonV2, CardWithLabelV2, LabelV2, StatusV2, themeV2 } from '@atomica.co/components';
import {
  ReservationPaymentMethod,
  SpaceReservation,
  SpaceReservationPaymentStatus,
  toFullName
} from '@atomica.co/irori';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { RESERVATION_PAYMENT_STATUS_LABELS } from '../../../texts/reservation-payment-status-text';
import {
  toPaymentStatusFromSpaceReservation,
  toStatusFromSpaceReservation,
  toStatusLabelFromSpaceReservation
} from '../../../utils/space-reservation-util';

interface P {
  reservation: SpaceReservation;
  isShowSpacePayment: boolean;
  // NOTE: ドロップインユーザーが予約時に支払い方法を選択できるかのフラグ
  isFeatureReservationSpacePayment: boolean;
  onClickUpdatePaymentStatus: () => void;
}

const ReservationDetailOther: React.FC<P> = React.memo(props => {
  const { reservation, isShowSpacePayment, isFeatureReservationSpacePayment, onClickUpdatePaymentStatus } = props;

  const isShowPaymentStatus = useMemo(() => {
    const flgs = isFeatureReservationSpacePayment && isShowSpacePayment;
    const paymentFlg = reservation.paymentStatus && reservation.paymentMethod === ReservationPaymentMethod.CASH;
    return flgs && paymentFlg;
  }, [isFeatureReservationSpacePayment, isShowSpacePayment, reservation.paymentMethod, reservation.paymentStatus]);

  const paymentStatusUpdateLabel = useMemo(() => {
    if (reservation?.paymentStatus === SpaceReservationPaymentStatus.PAID) {
      return RESERVATION_PAYMENT_STATUS_LABELS.unpaid.concat('にする');
    }
    if (reservation?.paymentStatus === SpaceReservationPaymentStatus.UNPAID) {
      return RESERVATION_PAYMENT_STATUS_LABELS.paid.concat('にする');
    }
  }, [reservation]);

  return (
    <CardContent>
      <StatusWrapper>
        <LabelV2 text='ステータス' />
        <StatusV2
          status={toStatusFromSpaceReservation(reservation)}
          label={toStatusLabelFromSpaceReservation(reservation)}
        />
      </StatusWrapper>
      {isShowPaymentStatus && (
        <StatusWrapper>
          <LabelV2 text='支払いステータス' />
          <StatusWithButton>
            <StatusV2
              status={toPaymentStatusFromSpaceReservation(reservation)}
              label={reservation.paymentStatus ? RESERVATION_PAYMENT_STATUS_LABELS[reservation.paymentStatus] : '-'}
            />
            <ButtonV2 onClick={onClickUpdatePaymentStatus} label={paymentStatusUpdateLabel} type={'tertiary'} />
          </StatusWithButton>
        </StatusWrapper>
      )}
      <CardWithLabelV2
        label='予約日時'
        text={reservation.createdAt ? format(reservation.createdAt, 'yyyy/MM/dd HH:mm') : '-'}
      />
      <CardWithLabelV2
        label='予約確定/却下日時'
        text={toFullName(reservation.updatedUser) ? format(reservation.updatedAt!, 'yyyy/MM/dd HH:mm') : '-'}
      />
      <CardWithLabelV2 label='予約確定/却下の操作者' text={toFullName(reservation.updatedUser) || '-'} />
    </CardContent>
  );
});

ReservationDetailOther.displayName = 'ReservationDetailOther';
export default ReservationDetailOther;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  margin-top: ${themeV2.mixins.v2.spacing * 3}px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const StatusWithButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
