import {
  ButtonV2,
  CardData,
  CardDeckRef,
  Component,
  customMedia,
  DirectionEnum,
  PositionEnum,
  ReactUseGestureEventHandlers,
  themeV2,
  Tips,
  useSafeCallback,
  useSafeState,
  useUnmountRef,
  YSwipableDeck
} from '@atomica.co/components';
import { BaseDto, toLatestUserPath, User } from '@atomica.co/irori';
import { Height, Id, Width } from '@atomica.co/types';
import { builder, hasLength } from '@atomica.co/utils';
import { addMinutes, endOfDay } from 'date-fns';
import React, { CSSProperties, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getBsSpace } from '../../../__generated/user/bs-space/bs-space';
import DefaultModal from '../../../components/modal/DefaultModal';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { database } from '../../../firebase';
import { isInLineClient } from '../../../line';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import { toZonedDate } from '../../../utils/date-util';
import ReservationCardV2 from './reservation-card/ReservationCardV2';

const TIPS_MESSAGE =
  '本日の予定はありません。\n設備の予約をするか\n設備の予約の招待を受け取ると\n本日の予定が表示されます。';

interface P {
  base: BaseDto;
  user: User;
}

const AccountHome: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath } = usePath();
  const { openSnackbar } = useSnackbarV2();
  const ref = useRef<CardDeckRef>(null);
  const unmountRef = useUnmountRef();
  const [cardDataList, setCardDataList] = useSafeState<CardData[]>(unmountRef, []);
  const [cardHeight, setCardHeight] = useSafeState<Height>(unmountRef, 0);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, false);
  const [checkoutId, setCheckoutId] = useSafeState<Id>(unmountRef);

  const fetchResourceUsages = useSafeCallback(async (): Promise<void> => {
    setIsLoading(true);
    const { data } = await getBsSpace().fetchResourceUsages(base.baseId, user.userId);
    const cardDataList = data.cards.map((card, index) => {
      const node = (bind: () => ReactUseGestureEventHandlers, fronting: boolean): React.ReactNode => (
        <ReservationCardV2
          fronting={fronting}
          baseId={base.baseId}
          userId={user.userId}
          lineLiffId={base.lineLiffId}
          closingDatetime={
            base?.closingHours ? addMinutes(toZonedDate(base.closingHours, base.timezone), 5) : endOfDay(new Date())
          }
          card={card}
          openModal={() => {
            setIsModalOpen(true);
            setCheckoutId(card.spaceReservationId);
          }}
          setCardHeight={setCardHeight}
          bind={bind}
        />
      );

      return builder<CardData>()
        .id(card.cardId)
        .visible(true)
        .propsIndex(index)
        .position(PositionEnum.CENTER)
        .node(node)
        .build();
    });

    setCardDataList(cardDataList);
    setIsLoading(false);
  }, [
    base.baseId,
    base.closingHours,
    base.lineLiffId,
    base.timezone,
    setCardDataList,
    setCardHeight,
    setCheckoutId,
    setIsLoading,
    setIsModalOpen,
    user.userId
  ]);

  useEffect(() => {
    fetchResourceUsages();
  }, [fetchResourceUsages]);

  const checkout = useSafeCallback(async (): Promise<void> => {
    if (!checkoutId) return;
    const { status } = await getBsSpace().checkoutDropIn(base.baseId, checkoutId);

    if (status !== 200) {
      return openSnackbar('利用終了処理が失敗しました。', 'error', 3000);
    }

    openSnackbar('利用終了処理が成功しました。', 'success', 3000);
    setIsModalOpen(false);
    await database.ref(toLatestUserPath(base.baseCode)).set({ checkoutId });
    await fetchResourceUsages();
  }, [base.baseCode, base.baseId, checkoutId, fetchResourceUsages, openSnackbar, setIsModalOpen]);

  const openAccountMySchedulesScreen = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_MY_SCHEDULES);
  }, [openBasePath]);

  useEffect(() => {
    ref.current?.moveCard(PositionEnum.CENTER);
  }, [ref]);

  return (
    <Component loading={isLoading} style={styleForComponent} className='account-home' title='ホーム'>
      <Container data-testid='account-home'>
        <Content>
          {!hasLength(cardDataList) && (
            <>
              <NoReservationWrapper>
                <Tips message={TIPS_MESSAGE} />
              </NoReservationWrapper>
              <CenteredWrapper>
                <StyledButton
                  width={80}
                  size='large'
                  type='secondary'
                  label='すべての予定を確認'
                  onClick={openAccountMySchedulesScreen}
                />
              </CenteredWrapper>
            </>
          )}
          {hasLength(cardDataList) && (
            <>
              <LabelWrapper>
                <Label>本日の予定</Label>
                <StyledButton
                  width={40}
                  size='small'
                  type='tertiary'
                  label='すべての予定を確認'
                  onClick={openAccountMySchedulesScreen}
                />
              </LabelWrapper>
              <YSwipableDeck
                ref={ref}
                cardDataList={cardDataList}
                cardHeight={cardHeight}
                swipeDirection={DirectionEnum.UP}
              />
            </>
          )}
        </Content>
        <DefaultModal
          isOpen={isModalOpen}
          headerLabel='利用終了'
          rightButtonProps={{ label: '利用終了', onClick: checkout }}
          onClose={() => setIsModalOpen(false)}
        >
          <Text>利用終了後は入室できなくなります。よろしいですか？</Text>
        </DefaultModal>
      </Container>
    </Component>
  );
});

AccountHome.displayName = 'AccountHome';
export default AccountHome;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center'
};

const Container = styled.div`
  width: 100%;
  height: ${isInLineClient() ? '100%' : 'calc(100% - 100px)'};
  max-width: 1124px;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0 ${themeV2.mixins.v2.spacing}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  ${customMedia.lessThan('tiny')`
    padding: ${themeV2.mixins.v2.spacing}px 0 ;
  `};
`;

const Content = styled.div`
  width: 100%;
  max-width: ${MOBILE_MAX_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  min-width: ${MOBILE_MIN_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  ${customMedia.lessThan('tiny')`
    gap: ${themeV2.mixins.v2.spacing}px;
  `};
`;

const LabelWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
`;

const Label = styled.div`
  ${themeV2.mixins.v2.typography.title.medium}
`;

const NoReservationWrapper = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const StyledButton = styled(ButtonV2)<{ width: Width }>`
  && {
    width: ${({ width }) => `${width}%`};
    white-space: nowrap;
  }
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
`;
