import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BASE_CODE } from '@atomica.co/irori';
import { PathStr, URL } from '@atomica.co/types';
import { EMPTY, embedIdInPath, getParams, getPath, getQueryParams, uuid } from '@atomica.co/utils';
import { useLayoutEffect, useRef } from 'react';
import { Params, QueryParams } from '../../models/path-model';
import { PATH_IDS, Path } from '../../router/Routes';

const PATH_CHANGED_EVENT = 'pathchanged';

const POP_STATE_EVENT = 'popstate';

const IGNORED_DIRECTORIES: PathStr[] = [];

interface P {
  path: Path | undefined;
  params: Params;
  queryParams: QueryParams;
  generateBasePathWithQueryParams: (path: Path, queryParams: QueryParams) => Path;
  openBasePath: <HistoryState extends object>(path: Path, state?: HistoryState) => void;
  openBasePathWithQueryParams: <HistoryState extends object>(
    path: Path,
    queryParams: QueryParams,
    state?: HistoryState
  ) => void;
  openPath: <HistoryState extends object>(path: Path | undefined, state?: HistoryState) => void;
  openPathInNewTab: (path: Path) => void;
  addBrowserBackEventListener: () => void;
  removeBrowserBackEventListener: () => void;
  goBack: () => void;
  getHistoryState: <HistoryState extends object>() => HistoryState | undefined;
  setHistoryState: <HistoryState extends object>(state?: HistoryState, url?: URL) => void;
}

function usePath(): P {
  const unmountRef = useUnmountRef();
  const previousPathName = useRef<PathStr>(window.location.pathname);
  const previousSearch = useRef<PathStr>(window.location.search);
  const [path, setPath] = useSafeState<Path | undefined>(
    unmountRef,
    getPath<Path>(Object.values(Path), PATH_IDS, previousPathName.current)
  );
  const [params, setParams] = useSafeState<Params>(
    unmountRef,
    getParams<Params>(Object.values(Path), PATH_IDS, window.location.pathname)
  );
  const [queryParams, setQueryParams] = useSafeState<QueryParams>(
    unmountRef,
    getQueryParams<QueryParams>(window.location.search)
  );

  const updatePathInfo = useSafeCallback((): void => {
    const nextPathName = window.location.pathname;
    const nextSearch = window.location.search;
    if (nextPathName === previousPathName.current && (!nextSearch || nextSearch === previousSearch.current)) return;

    const paths = Object.values(Path);
    previousPathName.current = nextPathName;

    const nextPath = getPath<Path>(paths, PATH_IDS, nextPathName);
    if (nextPath) setPath(nextPath);

    const nextParams = getParams<Params>(paths, PATH_IDS, window.location.pathname, IGNORED_DIRECTORIES);
    setParams(nextParams);

    const nextQueryParams = getQueryParams<QueryParams>(window.location.search);
    setQueryParams(nextQueryParams);
  }, [setPath, setParams, setQueryParams]);

  useLayoutEffect(() => {
    window.addEventListener(PATH_CHANGED_EVENT, updatePathInfo);
    return () => window.removeEventListener(PATH_CHANGED_EVENT, updatePathInfo);
  }, [updatePathInfo]);

  const dispatchPathChangedEvent = useSafeCallback((): void => {
    window.dispatchEvent(new Event(PATH_CHANGED_EVENT));
  }, []);

  const openPath = useSafeCallback(
    <HistoryState extends object>(path: Path | undefined, state?: HistoryState): void => {
      if (!path) return;
      window.history.pushState(state ?? {}, '', `${path}`);
      dispatchPathChangedEvent();
    },
    [dispatchPathChangedEvent]
  );

  const openBasePath = useSafeCallback(
    <HistoryState extends object>(path: Path | undefined, state?: HistoryState): void => {
      if (!path) return;
      openPath(embedIdInPath(path, [BASE_CODE], [params[BASE_CODE]]), state);
    },
    [params, openPath]
  );

  const generateBasePathWithQueryParams = useSafeCallback(
    (path: Path, queryParams: QueryParams): Path => {
      return attachQueryParam(embedIdInPath(path, [BASE_CODE], [params[BASE_CODE]]), queryParams);
    },
    [params]
  );

  const openBasePathWithQueryParams = useSafeCallback(
    <HistoryState extends object>(path: Path, queryParams: QueryParams, state?: HistoryState): void => {
      if (!path) return;
      openPath(generateBasePathWithQueryParams(path, queryParams), state);
    },
    [generateBasePathWithQueryParams, openPath]
  );

  const openPathInNewTab = useSafeCallback((path: Path): void => {
    if (!path) return;
    window.open(`${window.location.origin}${path}`, uuid());
  }, []);

  const goBack = useSafeCallback((): void => {
    window.history.back();
  }, []);

  const addBrowserBackEventListener = useSafeCallback((): void => {
    window.addEventListener(POP_STATE_EVENT, dispatchPathChangedEvent);
  }, [dispatchPathChangedEvent]);

  const removeBrowserBackEventListener = useSafeCallback((): void => {
    window.removeEventListener(POP_STATE_EVENT, dispatchPathChangedEvent);
  }, [dispatchPathChangedEvent]);

  const getHistoryState = useSafeCallback(
    <HistoryState extends object>(): HistoryState | undefined => window.history.state,
    []
  );

  const setHistoryState = useSafeCallback(
    <HistoryState extends object>(state?: HistoryState, url?: URL): void =>
      window.history.replaceState(state ?? {}, EMPTY, url),
    []
  );

  return {
    path,
    params,
    queryParams,
    generateBasePathWithQueryParams,
    openBasePath,
    openBasePathWithQueryParams,
    openPath,
    openPathInNewTab,
    addBrowserBackEventListener,
    removeBrowserBackEventListener,
    goBack,
    getHistoryState,
    setHistoryState
  };
}

const attachQueryParam = <T extends URL>(url: T, queryParams?: QueryParams): T => {
  if (!queryParams) return url;
  const queryString = Object.entries(queryParams)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
    .join('&');
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}${queryString}` as T;
};

export default usePath;
