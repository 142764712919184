import { HeaderV2, Screen, useSafeCallback } from '@atomica.co/components';
import { BaseDto, User } from '@atomica.co/irori';
import { Option } from '@atomica.co/types';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo/logo_knot_place_v2.png';
import { HEADER_HEIGHT } from '../../constants/common-const';
import { Labels } from '../../models/common-model';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import ConsoleSwitcher from './ConsoleSwitcher';
import LeftPanel from './LeftPanel';

export const DRAWER_WIDTH = 280;

enum HeaderActionEnum {
  SIGNOUT = 'signout'
}

const HEADER_ACTION_LABELS: Labels = { [HeaderActionEnum.SIGNOUT]: 'ログアウトする' };

interface P {
  isDrawerOpen?: boolean;
  base: BaseDto;
  user: User;
}

const ConsoleScreen: React.FC<P> = React.memo((props: P) => {
  const { isDrawerOpen = false, base, user } = props;
  const { path, openBasePath } = usePath();

  const OPTIONS = Object.values(HeaderActionEnum);
  const logoUrl = useMemo(() => base.logoURL || logo, [base.logoURL]);

  const handleIconMenuClicked = useSafeCallback(
    (option: Option): void => {
      switch (option) {
        case HeaderActionEnum.SIGNOUT:
          openBasePath(Path.SIGN_OUT);
          break;
        default:
          throw new Error(`${option} is out of target.`);
      }
    },
    [openBasePath]
  );

  return (
    <Screen className='console-screen'>
      <Container>
        <CssBaseline />

        <HeaderV2
          logo={logoUrl}
          icon={user.photoURL}
          labels={HEADER_ACTION_LABELS}
          options={OPTIONS}
          onClickMenuItem={handleIconMenuClicked}
        />
        <Content>
          <LeftPanel base={base} isDrawerOpen={isDrawerOpen} path={path} openBasePath={openBasePath} />

          {!!base && (
            <ConsoleSwitcherWrapper>
              <ConsoleSwitcher isDrawerOpen path={path} base={base} user={user} onChange={openBasePath} />
            </ConsoleSwitcherWrapper>
          )}
        </Content>
      </Container>
    </Screen>
  );
});

ConsoleScreen.displayName = 'ConsoleScreen';
export default ConsoleScreen;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;

  @supports (height: 1dvh) {
    min-height: 100dvh;
  }
`;

const Content = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  width: 100%;
  display: flex;
`;

const ConsoleSwitcherWrapper = styled.div`
  flex: 1;
  max-width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;
