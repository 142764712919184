import { BaseDto, MenuDef, MenuType, Shot } from '@atomica.co/irori';
import { Code, Name, URL as UrlPath, USER_ID } from '@atomica.co/types';
import { embedIdInPath, EMPTY, FIRST_INDEX, hasLength, SLASH } from '@atomica.co/utils';
import querystring from 'querystring';
import { Path, PATH_IDS } from '../router/Routes';
import { fromAccountTab } from '../converters/path-converter';

const CONSUMER_DEFAULT_PATH_AND_NAME: [path: Path, name: Name] = [Path.ACCOUNT_HOME, 'ホーム'];

export const getReceptionURL = (shotUsage: Shot | undefined, baseCode: Code): UrlPath => {
  const userId = !!shotUsage && !!shotUsage.user ? shotUsage.user.userId : EMPTY;
  const _shotUsage = shotUsage
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ({ ...shotUsage, receiptName: shotUsage.receiptName?.replace(/&/g, '%26') } as any)
    : undefined;
  const queryParams = querystring.stringify(_shotUsage) + `&${USER_ID}=${userId}`;

  return (
    window.location.origin +
    embedIdInPath(Path.SHOT_RECEPTION, PATH_IDS, [baseCode]) +
    `?${encodeURIComponent(queryParams)}`
  );
};

export const getBaseFromURL = (url: UrlPath): Code => {
  const findingWord = '/base/';
  if (!url.includes(findingWord)) return EMPTY;
  return url.split(findingWord)[1].split(SLASH)[FIRST_INDEX];
};

export const encodeEntireUrl = (url: UrlPath): UrlPath => {
  const urlObject = new URL(url);
  const encodedProtocol = encodeURIComponent(urlObject.protocol);
  const encodedSlash = encodeURIComponent('//');
  const encodedHostname = encodeURIComponent(urlObject.hostname);
  const encodedPathname = encodeURIComponent(urlObject.pathname);
  const encodedSearch = encodeURIComponent(urlObject.search);
  const encodedHash = encodeURIComponent(urlObject.hash);
  return `${encodedProtocol}${encodedSlash}${encodedHostname}${encodedPathname}${encodedSearch}${encodedHash}`;
};

const getConsumerHomeMenuDef = (base: BaseDto): MenuDef | undefined => {
  const menus = (base.menus ?? []).filter(menu => menu.menuType == MenuType.CONSUMER);

  const parentMenu = menus.find(
    menu => menu.isHome || (hasLength(menu.baseSubMenus) && menu.baseSubMenus.some(submenu => submenu.isHome))
  );
  if (!parentMenu) return undefined;

  if (parentMenu.isHome) return parentMenu.menuDef;
  return parentMenu.baseSubMenus?.find(submenu => submenu.isHome)?.menuDef;
};

export const getBaseConsumerHomePathAndName = (base: BaseDto): [path: Path, name: Name] => {
  const menuDef = getConsumerHomeMenuDef(base);
  if (!menuDef) {
    return CONSUMER_DEFAULT_PATH_AND_NAME;
  }
  try {
    return [fromAccountTab(menuDef.menuDefCode), menuDef.menuDefName];
  } catch {
    return CONSUMER_DEFAULT_PATH_AND_NAME;
  }
};
