import { ColgroupV2, Component, TableV2, TbodyV2, TheadV2, themeV2, ThV2, TrV2 } from '@atomica.co/components';
import { BaseDto, BillingV2, Sort, SpaceReservation, SpaceReservationUsageSortColumn } from '@atomica.co/irori';
import { Hour, Index } from '@atomica.co/types';
import { hasLength, noop, ZERO } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { NO_DATA_TEXT } from '../../../texts/common-text';
import SpaceUsageRow from './SpaceUsageRow';

const TABLE_COLUMNS: TableColumn[] = [
  { label: '利用日時', sortCol: SpaceReservationUsageSortColumn.START_AT },
  { label: '会議室' },
  { label: '利用料 / 時間（円・税抜）', align: 'right' },
  { label: '課金対象の利用時間' },
  { label: '金額（円・税抜）', align: 'right' }
];

interface TableColumn {
  label: string;
  align?: string;
  sortCol?: SpaceReservationUsageSortColumn;
}

interface P {
  sort: Sort;
  sortKey: SpaceReservationUsageSortColumn;
  billing: BillingV2;
  reservations: SpaceReservation[];
  base: BaseDto;
}

const BillingSpaceUsages: React.FC<P> = React.memo(props => {
  const { sort, sortKey, billing, reservations } = props;

  const freeTier = useMemo<Hour>(() => {
    return billing?.contract?.contractPlan?.freeTier ?? ZERO;
  }, [billing]);

  return (
    <Component className='billing-space-usage-detail' style={styleForComponent}>
      <Container>
        <TableWrapper>
          <TableV2 shape='circle'>
            <ColgroupV2 width={56} />
            <ColgroupV2 width={112} />
            <ColgroupV2 width={56} />
            <ColgroupV2 width={112} />
            <ColgroupV2 width={56} />
            <TheadV2>
              <TrV2>
                {TABLE_COLUMNS.map((column: TableColumn, idx: Index) => (
                  <ThV2 key={idx} onClick={noop}>
                    {column.sortCol ? (
                      <SortColumn>
                        <Label align={column.align ?? 'left'}>{column.label}</Label>
                        {column.sortCol === sortKey &&
                          (sort === Sort.ASC ? <CustomArrowUpwardIcon /> : <CustomArrowDownwardIcon />)}
                      </SortColumn>
                    ) : (
                      <Label align={column.align ?? 'left'}>{column.label}</Label>
                    )}
                  </ThV2>
                ))}
              </TrV2>
            </TheadV2>

            <TbodyV2>
              {hasLength(reservations) &&
                reservations.map((reservation, index) => (
                  <SpaceUsageRow
                    key={index}
                    freeTier={freeTier}
                    currIndex={index}
                    currReservation={reservation}
                    reservations={reservations}
                    contract={billing.contract}
                  />
                ))}
            </TbodyV2>
          </TableV2>

          {!hasLength(reservations) && <NoSearchResult>{NO_DATA_TEXT}</NoSearchResult>}
        </TableWrapper>
      </Container>
    </Component>
  );
});

BillingSpaceUsages.displayName = 'BillingSpaceUsages';
export default BillingSpaceUsages;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${themeV2.mixins.v2.spacing * 10}px;
`;

const TableWrapper = styled.div`
  min-height: 92px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: ${themeV2.mixins.v2.color.background.white};
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const SortColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

const Label = styled.div<{ align: string }>`
  text-align: ${({ align }) => align};
`;

const CustomArrowDownwardIcon = styled(ArrowDownwardIcon)`
  ${themeV2.mixins.v2.typography.body.large};
`;

const CustomArrowUpwardIcon = styled(ArrowUpwardIcon)`
  ${themeV2.mixins.v2.typography.body.large};
`;

const NoSearchResult = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
  padding: ${themeV2.mixins.v2.spacing * 3}px;
`;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%'
};
