import {
  ButtonV2,
  CardV2,
  CardWithLabelV2,
  LabelV2,
  LinkButton,
  themeV2,
  useSafeCallback
} from '@atomica.co/components';
import { SpaceReservation, toFullName } from '@atomica.co/irori';
import { Gap } from '@atomica.co/types';
import { embedIdInPath, EMPTY, hasLength, HYPHEN } from '@atomica.co/utils';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import usePath from '../../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../../router/Routes';
import { RESERVATION_PAYMENT_METHOD_LABELS } from '../../../texts/payment-method-text';

interface P {
  // NOTE: Interfaceにしたい感がある
  reservation: SpaceReservation;
  // NOTE: 整備変更を許可するかしないかのフラグ
  isEnableEditEquipment: boolean;
  // NOTE: 支払い方法表示フラグ
  isShowSpacePayment: boolean;
  baseCode: string;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReservationDetailBasic: React.FC<P> = React.memo(props => {
  const { reservation, isEnableEditEquipment, setModalOpen, baseCode, isShowSpacePayment } = props;

  const { openPathInNewTab } = usePath();

  const openUserDetailScreen = useSafeCallback((): void => {
    if (!reservation) return;
    openPathInNewTab(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [baseCode, reservation.createdUser!.userId]));
  }, [baseCode, openPathInNewTab, reservation]);

  const ParticipantComponent = useMemo(() => {
    if (hasLength(reservation.participants)) {
      return reservation.participants.map((participant, index, arr) => {
        return (
          index % 2 === 0 && (
            <CardRow key={`participant-${index}`} gap={8}>
              <CardV2 text={toFullName(participant.user)} />
              {index + 1 < arr.length ? <CardV2 text={toFullName(arr[index + 1].user)} /> : <Dummy />}
            </CardRow>
          )
        );
      });
    }
    if (!hasLength(reservation.participants)) {
      return (
        <CardRow>
          <CardV2 text={HYPHEN} />
          <Dummy />
        </CardRow>
      );
    }
  }, [reservation.participants]);

  return (
    <CardContent>
      <CardRow>
        <CardWithLabelV2
          label='利用開始日時'
          text={reservation.startAt ? format(reservation.startAt, 'yyyy/MM/dd HH:mm') : EMPTY}
        />
        <CardWithLabelV2
          label='利用終了日時'
          text={reservation.endAt ? format(reservation.endAt, 'yyyy/MM/dd HH:mm') : EMPTY}
        />
      </CardRow>
      <CardRow>
        <CardWithLabelV2 label='設備カテゴリー' text={reservation.space?.baseResourceCategory?.categoryLabel ?? '-'} />
        <CardWithLabelV2
          label='設備名'
          text={reservation.space!.spaceName}
          // NOTE: 一時的無効化 (再計算ロジックの回収 + カレンダー連携の調節が必要)
          component={
            isEnableEditEquipment && (
              <ButtonV2 endIcon={<EditOutlinedIcon />} label='編集' onClick={() => setModalOpen(true)} />
            )
          }
        />
      </CardRow>
      <CardRow>
        <CardWithLabelV2
          label='法人'
          text={reservation.contract?.entityName || '-'}
          // component={
          //   reservation.contract?.billings && (
          //     <LinkButton hasIcon label='請求へ' onClick={openBillingDetailScreen} />
          //   )
          // }
        />
        <CardWithLabelV2
          label='予約者'
          text={toFullName(reservation.createdUser) || '-'}
          component={reservation.createdUser && <LinkButton hasIcon label='' onClick={openUserDetailScreen} />}
        />
      </CardRow>
      <div>
        <LabelV2 text='利用者' />
        {ParticipantComponent}
      </div>
      {isShowSpacePayment && reservation.paymentMethod && (
        <CardRow>
          <CardWithLabelV2 label={'支払い方法'} text={RESERVATION_PAYMENT_METHOD_LABELS[reservation.paymentMethod]} />
          <Dummy />
        </CardRow>
      )}
      {reservation.options?.map((option, index) => {
        return (
          <CardRow key={`option-${index}`}>
            <CardWithLabelV2 label={option.baseResourceCategoryOption!.optionLabel} text={option.value ?? EMPTY} />
            <Dummy />
          </CardRow>
        );
      })}
      {reservation.remarks && <CardWithLabelV2 label='備考' text={reservation.remarks} />}
    </CardContent>
  );
});

ReservationDetailBasic.displayName = 'ReservationDetailBasic';
export default ReservationDetailBasic;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  margin-top: ${themeV2.mixins.v2.spacing * 3}px;
`;

const CardRow = styled.div<{ gap?: Gap }>`
  display: flex;
  gap: ${({ gap }) => (gap ? gap : themeV2.mixins.v2.spacing * 2)}px;
`;

const Dummy = styled.div`
  width: 100%;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;
