import { LeftPanelV2, MenuItem, ParentMenu, useSafeCallback } from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BaseMenu,
  BaseSubMenu,
  isBaseFunctionToggleEnabled,
  MenuType
} from '@atomica.co/irori';
import { Label, URL } from '@atomica.co/types';
import { builder, EMPTY, hasLength, noop } from '@atomica.co/utils';
import React from 'react';
import styled from 'styled-components';
import { ANNOUNCEMENT_URL, HEADER_HEIGHT } from '../../constants/common-const';
import { toMenuPath } from '../../converters/base-menu-converter';
import { toIcon } from '../../converters/icon-converter';
import { MenuDefCodeEnum } from '../../enums/base-menu-enum';
import { IconCodeEnum } from '../../enums/icon-enum';
import { Path } from '../../router/Routes';

interface LeftPanelProps {
  base: BaseDto;
  isDrawerOpen?: boolean;
  path: Path | undefined;
  openBasePath: (path: Path) => void;
}

interface MasterMenuVisible {
  shot: boolean;
  resource: boolean;
}

const createMenuItem = ({
  menuLabel = EMPTY,
  isSelected,
  onClick
}: {
  menuLabel?: Label;
  isSelected: boolean;
  onClick: () => void;
}): MenuItem => ({
  label: menuLabel,
  isSelected,
  onClick
});

const createExternalLinkMenu = ({
  icon,
  menuLabel = EMPTY,
  externalId
}: {
  icon: React.ReactElement;
  menuLabel?: Label;
  externalId: URL;
}): ParentMenu => {
  return builder<ParentMenu>()
    .icon(icon)
    .mainItem(createMenuItem({ menuLabel, isSelected: false, onClick: () => window.open(externalId, '_blank') }))
    .build();
};

const createDefaultMenu = ({
  icon,
  currentPath,
  menuLabel = EMPTY,
  menuPath,
  openBasePath
}: {
  icon: React.ReactElement;
  currentPath?: Path;
  menuLabel?: Label;
  menuPath: Path;
  openBasePath: (path: Path) => void;
}): ParentMenu => {
  return builder<ParentMenu>()
    .icon(icon)
    .mainItem(
      createMenuItem({ menuLabel, isSelected: menuPath === currentPath, onClick: () => openBasePath(menuPath) })
    )
    .build();
};

const createHasSubItemsMenu = ({
  icon,
  currentPath,
  menuLabel = EMPTY,
  baseSubMenus,
  openBasePath
}: {
  icon: React.ReactElement;
  currentPath?: Path;
  menuLabel?: Label;
  baseSubMenus: BaseSubMenu[];
  openBasePath: (path: Path) => void;
}): ParentMenu => {
  const subMenuItems = baseSubMenus
    .map(subMenu => {
      const subMenuPath = toMenuPath(subMenu.menuDef?.menuDefCode);
      if (!subMenuPath) return null;
      return createMenuItem({
        menuLabel: subMenu.menuLabel ?? subMenu.menuDef?.menuDefName,
        isSelected: subMenuPath === currentPath,
        onClick: () => openBasePath(subMenuPath)
      });
    })
    .filter((subMenuItem): subMenuItem is MenuItem => subMenuItem !== null);

  return builder<ParentMenu>()
    .icon(icon)
    .mainItem(createMenuItem({ menuLabel, isSelected: false, onClick: noop }))
    .subItems(subMenuItems)
    .build();
};

const constructDatabaseMenus = (
  menus: BaseMenu[],
  currentPath: Path | undefined,
  openBasePath: (path: Path) => void
) => {
  return menus
    .map(menu => {
      const icon = toIcon(menu.menuDef?.iconCode);
      const menuLabel = menu.menuLabel || menu.menuDef?.menuDefName;

      // 1. 外部リンクの場合
      if (menu.externalUrl) {
        return createExternalLinkMenu({
          icon,
          menuLabel,
          externalId: menu.externalUrl
        });
      }

      // 2. サブメニューを持つ場合
      if (hasLength(menu.baseSubMenus)) {
        return createHasSubItemsMenu({
          icon,
          currentPath,
          menuLabel,
          baseSubMenus: menu.baseSubMenus,
          openBasePath
        });
      }

      // 3. 通常のメニュー
      const menuPath = toMenuPath(menu.menuDef?.menuDefCode);
      if (!menuPath) return null;

      return createDefaultMenu({
        icon,
        currentPath,
        menuLabel,
        menuPath,
        openBasePath
      });
    })
    .filter((menu): menu is ParentMenu => menu !== null);
};

const constructMasterMenu = ({
  currentPath,
  masterMenuVisible,
  openBasePath
}: {
  currentPath: Path | undefined;
  masterMenuVisible: MasterMenuVisible;
  openBasePath: (path: Path) => void;
}) => {
  const subMenuItems: MenuItem[] = [];
  if (masterMenuVisible.shot) {
    const menuPath = Path.SHOT_MASTER;
    subMenuItems.push(
      createMenuItem({
        menuLabel: '入館受付',
        isSelected: menuPath === currentPath,
        onClick: () => openBasePath(menuPath)
      })
    );
  }

  if (masterMenuVisible.resource) {
    const menuPath = Path.RESOURCE_MASTER;
    subMenuItems.push(
      createMenuItem({
        menuLabel: 'リソース',
        isSelected: menuPath === currentPath,
        onClick: () => openBasePath(menuPath)
      })
    );
  }

  if (!hasLength(subMenuItems)) return [];

  const masterMenu = builder<ParentMenu>()
    .icon(toIcon(IconCodeEnum.MASTER))
    .mainItem(createMenuItem({ menuLabel: 'マスタ設定', isSelected: false, onClick: noop }))
    .subItems(subMenuItems)
    .build();

  return [masterMenu];
};

const constructFixedMenu = () => {
  const announcementMenu = createExternalLinkMenu({
    icon: toIcon(IconCodeEnum.ANNOUNCEMENT),
    menuLabel: 'お知らせ',
    externalId: ANNOUNCEMENT_URL
  });

  return [announcementMenu];
};

const LeftPanel: React.FC<LeftPanelProps> = ({ base, isDrawerOpen = true, path, openBasePath }) => {
  const constructDisplayMenus = useSafeCallback(() => {
    const consoleMenus = base.menus?.filter(menu => menu.menuType === MenuType.CONSOLE) ?? [];

    // DBに設定されているメニューの構築
    const databaseMenus = constructDatabaseMenus(consoleMenus, path, openBasePath);

    // マスタ設定メニューの構築
    const masterMenus = constructMasterMenu({
      currentPath: path,
      openBasePath,
      masterMenuVisible: {
        shot: base.menus?.some(menu => menu.menuDef?.menuDefCode === MenuDefCodeEnum.CONSUMER_SHOT) ?? false,
        resource: isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESOURCE_MASTER)
      }
    });

    // 固定メニューの構築
    const fixedMenus = constructFixedMenu();

    return [...databaseMenus, ...masterMenus, ...fixedMenus];
  }, [base, path, openBasePath]);

  if (!isDrawerOpen) return null;

  return (
    <LeftPanelWrapper data-testid='console-screen-left-panel-wrapper'>
      <LeftPanelV2 headerPhotoURL={base.photoURL} headerName={base.baseName} menus={constructDisplayMenus()} />
    </LeftPanelWrapper>
  );
};

export default LeftPanel;

const LeftPanelWrapper = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT}px);
  position: relative;

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;
