/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  CheckoutDropIn200,
  CreateDropIn200,
  CreateDropInBody,
  CreateSpaceReservationPayment200,
  CreateSpaceReservationPaymentBody,
  ExtendDropIn200,
  ExtendDropInBody,
  FetchAccesses200,
  FetchAccessesParams,
  FetchDropIn200,
  FetchResourceUsages200,
  SaveAccess200,
  SaveAccessBody,
  SearchDropInItems200
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';
import type { BodyType } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsSpace = () => {
/**
 * ドロップインプランを取得する
 * @summary ドロップインプランを取得する
 */
const searchDropInItems = (
    baseId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchDropInItems200>(
      {url: `/user/base/${baseId}/drop-in`, method: 'GET'
    },
      options);
    }
  /**
 * ドロップイン情報を新規作成する
 * @summary ドロップイン情報を新規作成する
 */
const createDropIn = (
    baseId: string,
    createDropInBody: BodyType<CreateDropInBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<CreateDropIn200>(
      {url: `/user/base/${baseId}/drop-in`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createDropInBody
    },
      options);
    }
  /**
 * ドロップイン情報を更新する
 * @summary ドロップイン情報を更新する
 */
const extendDropIn = (
    baseId: string,
    dropInId: string,
    extendDropInBody: BodyType<ExtendDropInBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<ExtendDropIn200>(
      {url: `/user/base/${baseId}/drop-in/${dropInId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: extendDropInBody
    },
      options);
    }
  /**
 * ドロップインデータを取得する
 * @summary ドロップインデータを取得する
 */
const fetchDropIn = (
    baseId: string,
    dropInId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchDropIn200>(
      {url: `/user/base/${baseId}/drop-in/${dropInId}`, method: 'GET'
    },
      options);
    }
  /**
 * 指定したドロップインデータに対してチェックアウトする
 * @summary 指定したドロップインデータに対してチェックアウトする
 */
const checkoutDropIn = (
    baseId: string,
    dropInId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<CheckoutDropIn200>(
      {url: `/user/base/${baseId}/drop-in/${dropInId}/checkout`, method: 'POST'
    },
      options);
    }
  /**
 * Stripeの決済ページのURLを生成する
 * @summary Stripeの決済ページのURLを生成する
 */
const createSpaceReservationPayment = (
    baseId: string,
    spaceReservationId: string,
    createSpaceReservationPaymentBody: BodyType<CreateSpaceReservationPaymentBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<CreateSpaceReservationPayment200>(
      {url: `/user/base/${baseId}/space-reservation/${spaceReservationId}/payment`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createSpaceReservationPaymentBody
    },
      options);
    }
  /**
 * 本日の予定情報を取得する
 * @summary 本日の予定情報を取得する
 */
const fetchResourceUsages = (
    baseId: string,
    userId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchResourceUsages200>(
      {url: `/user/base/${baseId}/user/${userId}/resource-usage`, method: 'GET'
    },
      options);
    }
  /**
 * 入退室情報を保存する
 * @summary 入退室情報を保存する
 */
const saveAccess = (
    baseId: string,
    userId: string,
    saveAccessBody: BodyType<SaveAccessBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveAccess200>(
      {url: `/user/base/${baseId}/user/${userId}/access`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveAccessBody
    },
      options);
    }
  /**
 * 入退室情報を取得する
 * @summary 入退室情報を取得する
 */
const fetchAccesses = (
    baseId: string,
    userId: string,
    params?: FetchAccessesParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchAccesses200>(
      {url: `/user/base/${baseId}/user/${userId}/access`, method: 'GET',
        params
    },
      options);
    }
  return {searchDropInItems,createDropIn,extendDropIn,fetchDropIn,checkoutDropIn,createSpaceReservationPayment,fetchResourceUsages,saveAccess,fetchAccesses}};
export type SearchDropInItemsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['searchDropInItems']>>>
export type CreateDropInResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['createDropIn']>>>
export type ExtendDropInResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['extendDropIn']>>>
export type FetchDropInResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchDropIn']>>>
export type CheckoutDropInResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['checkoutDropIn']>>>
export type CreateSpaceReservationPaymentResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['createSpaceReservationPayment']>>>
export type FetchResourceUsagesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchResourceUsages']>>>
export type SaveAccessResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['saveAccess']>>>
export type FetchAccessesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchAccesses']>>>
