import { themeV2, themeV3 } from '@atomica.co/components';
import {
  ContractPlanUtils,
  PaymentMethod,
  SpaceReservationStatus,
  SpaceReservationType,
  TaxDiv
} from '@atomica.co/irori';
import { Hour, Price, Text } from '@atomica.co/types';
import { EMPTY, isZero, ZERO } from '@atomica.co/utils';
import { Divider } from '@material-ui/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import PriceService from '../../../services/price-service';
import { SPACE_RESERVATION_TYPE_LABEL } from '../../../texts/space-text';
import { calcUsagePeriod } from '../../../utils/space-reservation-util';

interface SpaceReservation {
  spaceName: string;
  reservationType: SpaceReservationType;
  pricePerHour: number;
  billingAmount: number;
  taxRate: number;
  status: SpaceReservationStatus;
  paymentMethod?: PaymentMethod;
  startAt?: Date;
  endAt?: Date;
  taxDiv: TaxDiv;
}

interface P {
  reservation: SpaceReservation;
  discountRate?: number;
  // NOTE: 契約ユーザーであるか
  isContractUser?: boolean;
  // NOTE: 該当のPlanに割引が適用されているか
  isContractPlanDiscountRateTarget?: boolean;
  // NOTE: 該当のPlanに割引が適応されているか
  isContractPlanFreeTierTarget?: boolean;
}

const ReservationStatement: React.FC<P> = React.memo(props => {
  const { reservation, discountRate, isContractPlanDiscountRateTarget, isContractPlanFreeTierTarget, isContractUser } =
    props;
  const { spaceName, startAt, endAt, reservationType, pricePerHour, billingAmount, taxRate, status, taxDiv } = reservation;

  // 利用時間の計算
  const currentUsage = useMemo<Hour>(() => {
    if (!startAt || !endAt) return ZERO;
    return calcUsagePeriod(reservationType, startAt, endAt);
  }, [endAt, reservationType, startAt]);

  // 単価表示
  const priceText = useMemo<Text>(() => {
    if (isZero(pricePerHour)) return EMPTY;
    return '¥' + pricePerHour.toLocaleString() + ' / ' + SPACE_RESERVATION_TYPE_LABEL[reservationType];
  }, [reservationType, pricePerHour]);

  // 割引率の計算
  const calculateDiscountRate = useMemo<number>(() => {
    return ContractPlanUtils.applyDiscount(discountRate ?? ZERO, Boolean(isContractPlanDiscountRateTarget));
  }, [discountRate, isContractPlanDiscountRateTarget]);

  const totalPricesAndTaxPrice = PriceService.getTotalPricesAndTaxPrice({
    unitPrice: pricePerHour,
    quentity: currentUsage,
    taxDiv: taxDiv,
    taxRate: taxRate
  });

  // 消費税の計算
  const { taxPrice, taxIncludedTotalPrice } = totalPricesAndTaxPrice;

  // 割引額の計算
  const contractDiscount = useMemo<Price>(() => {
    return Math.round((totalPricesAndTaxPrice.taxIncludedTotalPrice * calculateDiscountRate) / 100);
  }, [calculateDiscountRate, totalPricesAndTaxPrice.taxIncludedTotalPrice]);

  const TotalPrice = useMemo(() => {
    switch (status) {
      case SpaceReservationStatus.CANCELED:
      case SpaceReservationStatus.REJECTED:
      case SpaceReservationStatus.REMOVED_GOOGLE_CALENDAR:
        return (
          <TotalPriceArea>
            <LargeText>合計</LargeText>
            <TotalPriceLabel>¥{Math.round(taxIncludedTotalPrice - contractDiscount).toLocaleString()}</TotalPriceLabel>
          </TotalPriceArea>
        );
      default:
        return (
          <TotalPriceArea>
            <LargeText>合計</LargeText>
            <TotalPriceLabel>¥{billingAmount.toLocaleString()}</TotalPriceLabel>
          </TotalPriceArea>
        );
    }
  }, [billingAmount, contractDiscount, status, taxIncludedTotalPrice]);

  return (
    <CardContent>
      <Content>
        <CardRow>
          <BoldLabel>{spaceName ?? EMPTY}利用料金</BoldLabel>
          <GrayLargeText>{priceText}</GrayLargeText>
        </CardRow>
        {reservationType === SpaceReservationType.TIME && (
          <>
            <CardRow>
              <BoldLabel>{'利用時間'}</BoldLabel>
              <GrayLargeText>{currentUsage}時間</GrayLargeText>
            </CardRow>
          </>
        )}
        {reservationType === SpaceReservationType.DATE && (
          <>
            <CardRow>
              <BoldLabel>利用日数</BoldLabel>
              <GrayLargeText>{currentUsage}日</GrayLargeText>
            </CardRow>
            <CardRow>
              <BoldLabel>利用部屋数</BoldLabel>
              <GrayLargeText>{'1'}部屋</GrayLargeText>
            </CardRow>
          </>
        )}
      </Content>
      {!isZero(taxIncludedTotalPrice) && (
        <Content>
          <StyledDivider />
          <CardRow>
            <BoldLabel>小計</BoldLabel>
            <GrayLargeText>¥{Math.abs(taxIncludedTotalPrice).toLocaleString()}</GrayLargeText>
          </CardRow>
          <CardRow>
            <GrayLargeText>うち消費税（{taxRate}%）</GrayLargeText>
            <GrayLargeText>¥{Math.abs(taxPrice).toLocaleString()}</GrayLargeText>
          </CardRow>
          {!!calculateDiscountRate && isContractUser && (
            <CardRow>
              <GrayLargeText>契約割引（{calculateDiscountRate}%）</GrayLargeText>
              <GrayLargeText>-¥{contractDiscount.toLocaleString()}</GrayLargeText>
            </CardRow>
          )}
        </Content>
      )}
      {TotalPrice}
      <RemarkWrapper>
        {isContractPlanFreeTierTarget && isContractUser && (
          <Remarks>※ 上記の合計金額は会議室利用無料枠を考慮しておりません</Remarks>
        )}
        {(status === SpaceReservationStatus.CANCELED ||
          status === SpaceReservationStatus.REJECTED ||
          status === SpaceReservationStatus.REMOVED_GOOGLE_CALENDAR
        ) && (
          <Remarks>※ 上記の合計金額は予約時の金額を表示しております</Remarks>
        )}
      </RemarkWrapper>
    </CardContent>
  );
});

ReservationStatement.displayName = 'ReservationStatement';
export default ReservationStatement;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  margin-top: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BoldLabel = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV3.mixins.v3.color.object.gray};
`;

const TotalPriceArea = styled(CardRow)`
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV3.mixins.v3.color.container.neutral.row};
  border-radius: 12px;
`;

const StyledDivider = styled(Divider)`
  color: ${themeV3.mixins.v3.color.border.gray};
`;

const LargeText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const GrayLargeText = styled(LargeText)`
  color: ${themeV3.mixins.v3.color.object.gray};
`;

const TotalPriceLabel = styled(LargeText)`
  font-weight: 700;
`;

const Remarks = styled.small`
  color: ${themeV3.mixins.v3.color.object.gray};
  text-align: right;
`;

const RemarkWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;
