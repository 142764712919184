import { EnvDef } from '@atomica.co/utils';
import { datadogRum } from '@datadog/browser-rum';
import React, { useContext, useEffect, useMemo } from 'react';
import AuthGuard, { AuthGuardChildProps } from '../../../auth/AuthGuard';
import Screen from '../../../components/screen/Screen';
import env from '../../../env/env';
import usePath from '../../../redux/hooks/usePath';
import { screenContext } from '../../../redux/hooks/useScreenContext';
import { Path } from '../../../router/Routes';
import RecordAccessScreen from '../../access/RecordAccessScreen';
import ConsoleScreen from '../../console/ConsoleScreen';
import ConsumerScreen from '../../consumer/ConsumerScreen';
import RegisterContractMemberV2Screen from '../../contract-v2/RegisterContractMemberScreen';
import RegisterContractMemberScreen from '../../contract/RegisterContractMemberScreen';
import RegisteredScreen from '../../registered/RegisteredScreen';
import ShotReceptionScreen from '../../shot/ShotReceptionScreen';

const GuardedScreens: React.FC<AuthGuardChildProps> = React.memo<AuthGuardChildProps>(props => {
  const { base, user } = props;
  const baseLoaded = !!base;
  const userLoaded = !!user;
  const { path } = usePath();
  const { isDrawerOpen } = useContext(screenContext);

  const screen = useMemo<React.ReactElement | undefined>(() => {
    if (!baseLoaded || !userLoaded) return;

    switch (path) {
      case Path.SHOT_RECEPTION:
        return <ShotReceptionScreen base={base} user={user} />;

      case Path.CONTRACT_MEMBER_REGISTERED_V2:
        return <RegisterContractMemberV2Screen base={base} user={user} />;

      case Path.ACCOUNT_HOME:
      case Path.ACCOUNT_SHOT:
      case Path.ACCOUNT_SHARE:
      case Path.RESERVE_SPACE:
      case Path.ACCOUNT_EVENTS:
      case Path.ACCOUNT_PROFILE:
      case Path.ACCOUNT_PROFILE_V2:
      case Path.MEMBERS:
      case Path.DROP_IN:
        return <ConsumerScreen isDrawerOpen={isDrawerOpen} base={base} user={user} />;

      case Path.ACCOUNT_MY_SCHEDULES:
      case Path.ACCOUNT_EVENT_ENTRY_FORM:
      case Path.ACCOUNT_RESERVATION_DETAIL:
      case Path.ACCOUNT_POINT_USAGE_LOGS:
      case Path.RESERVE_SPACE_CONFIRM:
      case Path.RESERVE_SPACE_EDIT:
      case Path.RESERVE_SPACE_EXTENSION:
      case Path.RESERVE_SPACE_AUTO_CONFIRM:
      case Path.RESERVE_SPACE_COMPLETE:
      case Path.QUESTIONNAIRE_ANSWER:
      case Path.REGISTER_ACCOUNT_WISH:
      case Path.EDIT_ACCOUNT_WISH:
      case Path.ACCOUNT_WISH_LIST:
      case Path.MEMBER_PROFILE:
      case Path.ACCOUNT_SETTING:
      case Path.RESEND_REGISTER_CREDIT_CARD:
      case Path.DROP_IN_CONFIRM:
      case Path.DROP_IN_COMPLETE:
        return <ConsumerScreen base={base} user={user} />;

      case Path.TIMELINE:
      case Path.VISITORS:
      case Path.USER_SEARCH_V2:
      case Path.WISH_LIST:
      case Path.USER_SEARCH:
      case Path.CONTRACT_LIST:
      case Path.CONTRACT_DETAILS:
      case Path.REGISTER_CONTRACT:
      case Path.CONTRACT_LIST_V2:
      case Path.BILLING_LIST_V2:
      case Path.EVENT_V2_LIST:
      case Path.QUESTIONNAIRE_LIST:
      case Path.RESERVATION_LIST:
      case Path.DIARY:
      case Path.EXPORT:
      case Path.SHOT_MASTER:
      case Path.RESOURCE_MASTER:
        return <ConsoleScreen isDrawerOpen={isDrawerOpen} base={base} user={user} />;

      case Path.EVENT_V2_DETAILS:
      case Path.EDIT_EVENT_V2_DETAILS:
      case Path.REGISTER_EVENT_V2:
      case Path.EDIT_EVENT_V2:
      case Path.REGISTER_EVENT_SCHEDULE_V2:
      case Path.EDIT_EVENT_SCHEDULE_V2:
      case Path.EVENT_SCHEDULE_V2_DETAILS:
      case Path.EVENT_SCHEDULE_PARTICIPANTS_V2:
      case Path.EVENT_SCHEDULE_GROUPS_V2:
      case Path.ENTRY_FORM_MESSAGES:
      case Path.REMIND_MESSAGES:
      case Path.REGISTER_QUESTIONNAIRE:
      case Path.EDIT_QUESTIONNAIRE:
      case Path.QUESTIONNAIRE_DETAILS:
      case Path.RESERVATION_DETAIL:
      case Path.CONTRACT_DETAILS_V2_DETAIL:
      case Path.USER_OVERVIEW:
      case Path.USER_WISH:
      case Path.USER_DIALOGUE_LOG:
      case Path.USER_EVENT_LOG:
      case Path.USER_SPACE_USAGE_LOG:
      case Path.USER_CHECK_IN_HISTORY:
      case Path.USER_POINT_USAGE_LOG:
      case Path.USER_ROOM_ENTRY_EXIT_HISTORY:
      case Path.USER_BULK_UPDATE:
      case Path.CONTRACT_DETAILS_V2_BILLING:
      case Path.CONTRACT_DETAILS_V2_MEMBER:
      case Path.CONTRACT_DETAILS_V2_LOGS:
      case Path.IMPORT_CONTRACT:
      case Path.REGISTER_CONTRACT_V2:
      case Path.EDIT_CONTRACT_V2:
      case Path.BILLING_DETAILS_V2_DETAIL:
      case Path.BILLING_DETAILS_V2_SPACE_USAGES:
      case Path.BILLING_DETAILS_V2_ACCESSES:
      case Path.BILLING_DETAILS_V2_LOGS:
      case Path.REGISTER_BILLING_V2:
      case Path.EDIT_BILLING_V2:
      case Path.REGISTER_WISH:
      case Path.WISH_DETAILS:
      case Path.EDIT_WISH:
      case Path.EDIT_RESOURCE_MASTER:
        return <ConsoleScreen base={base} user={user} />;

      /** @deprecated */
      case Path.REGISTERED:
        return <RegisteredScreen />;

      /** @deprecated */
      case Path.REGISTER_CONTRACT_MEMBER:
        return <RegisterContractMemberScreen base={base} user={user} />;

      /** @deprecated */
      case Path.RECORD_ACCESS:
        return <RecordAccessScreen base={base} user={user} />;
    }
  }, [baseLoaded, userLoaded, path, base, user, isDrawerOpen]);

  useEffect(() => {
    if (user && String(env) === EnvDef.PRODUCTION) {
      datadogRum.setUser({
        id: user.userId,
        email: user.email
      });
    }
  }, [user]);

  return (
    <Screen className='guarded-screens' loading={!baseLoaded && !userLoaded}>
      {screen}
    </Screen>
  );
});

GuardedScreens.displayName = 'GuardedScreens';
export default AuthGuard(GuardedScreens);
