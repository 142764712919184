import { EditorTabEnum, Hour, NumberOfPeople } from '../enums/common-enum';
import { Labels } from '../models/common-model';

export const NO_DATA_TEXT = 'データがありません';

export const NO_SEARCH_RESULTS_TEXT = '検索結果が見つかりません';

export const CONFERENCE_USAGE_HOURS_LABEL = '利用時間';

export const HOURS_LABELS: Labels = {
  [Hour.ONE]: '１時間',
  [Hour.ONE_AND_A_HALF]: '１時間半',
  [Hour.TWO]: '２時間',
  [Hour.TWO_AND_A_HALF]: '２時間半',
  [Hour.THREE]: '３時間',
  [Hour.THREE_AND_A_HALF]: '３時間半',
  [Hour.FOUR]: '４時間',
  [Hour.FOUR_AND_A_HALF]: '４時間半',
  [Hour.FIVE]: '５時間',
  [Hour.FIVE_AND_A_HALF]: '５時間半',
  [Hour.SIX]: '６時間',
  [Hour.SIX_AND_A_HALF]: '６時間半',
  [Hour.SEVEN]: '７時間',
  [Hour.SEVEN_AND_A_HALF]: '７時間半',
  [Hour.EIGHT]: '８時間',
  [Hour.EIGHT_AND_A_HALF]: '８時間半',
  [Hour.NINE]: '９時間',
  [Hour.NINE_AND_A_HALF]: '９時間半',
  [Hour.TEN]: '１０時間',
  [Hour.TEN_AND_A_HALF]: '１０時間半',
  [Hour.ELEVEN]: '１１時間',
  [Hour.ELEVEN_AND_A_HALF]: '１１時間半',
  [Hour.TWELVE]: '１２時間'
};

export const NUMBER_OF_PEOPLE_LABELS: Labels = {
  [NumberOfPeople.ONE]: '１人',
  [NumberOfPeople.TWO]: '２人',
  [NumberOfPeople.THREE]: '３人',
  [NumberOfPeople.FOUR]: '４人',
  [NumberOfPeople.FIVE]: '５人',
  [NumberOfPeople.SIX]: '６人',
  [NumberOfPeople.SEVEN]: '７人',
  [NumberOfPeople.EIGHT]: '８人',
  [NumberOfPeople.NINE]: '９人',
  [NumberOfPeople.TEN]: '１０人',
  [NumberOfPeople.ELEVEN]: '１１人',
  [NumberOfPeople.TWELVE]: '１２人',
  [NumberOfPeople.THIRTEEN]: '１３人',
  [NumberOfPeople.FOURTEEN]: '１４人',
  [NumberOfPeople.FIFTEEN]: '１５人',
  [NumberOfPeople.SIXTEEN]: '１６人',
  [NumberOfPeople.SEVENTEEN]: '１７人',
  [NumberOfPeople.EIGHTEEN]: '１８人',
  [NumberOfPeople.NINETEEN]: '１９人',
  [NumberOfPeople.TWENTY]: '２０人',
  [NumberOfPeople.TWENTY_ONE]: '２１人',
  [NumberOfPeople.TWENTY_TWO]: '２２人',
  [NumberOfPeople.TWENTY_THREE]: '２３人',
  [NumberOfPeople.TWENTY_FOUR]: '２４人',
  [NumberOfPeople.TWENTY_FIVE]: '２５人',
  [NumberOfPeople.TWENTY_SIX]: '２６人',
  [NumberOfPeople.TWENTY_SEVEN]: '２７人',
  [NumberOfPeople.TWENTY_EIGHT]: '２８人',
  [NumberOfPeople.TWENTY_NINE]: '２９人',
  [NumberOfPeople.THIRTY]: '３０人',
  [NumberOfPeople.THIRTY_ONE]: '３１人',
  [NumberOfPeople.THIRTY_TWO]: '３２人',
  [NumberOfPeople.THIRTY_THREE]: '３３人',
  [NumberOfPeople.THIRTY_FOUR]: '３４人',
  [NumberOfPeople.THIRTY_FIVE]: '３５人',
  [NumberOfPeople.THIRTY_SIX]: '３６人',
  [NumberOfPeople.THIRTY_SEVEN]: '３７人',
  [NumberOfPeople.THIRTY_EIGHT]: '３８人',
  [NumberOfPeople.THIRTY_NINE]: '３９人',
  [NumberOfPeople.FORTY]: '４０人',
  [NumberOfPeople.FORTY_ONE]: '４１人',
  [NumberOfPeople.FORTY_TWO]: '４２人',
  [NumberOfPeople.FORTY_THREE]: '４３人',
  [NumberOfPeople.FORTY_FOUR]: '４４人',
  [NumberOfPeople.FORTY_FIVE]: '４５人',
  [NumberOfPeople.FORTY_SIX]: '４６人',
  [NumberOfPeople.FORTY_SEVEN]: '４７人',
  [NumberOfPeople.FORTY_EIGHT]: '４８人',
  [NumberOfPeople.FORTY_NINE]: '４９人',
  [NumberOfPeople.FIFTY]: '５０人',
  [NumberOfPeople.FIFTY_ONE]: '５１人',
  [NumberOfPeople.FIFTY_TWO]: '５２人',
  [NumberOfPeople.FIFTY_THREE]: '５３人',
  [NumberOfPeople.FIFTY_FOUR]: '５４人',
  [NumberOfPeople.FIFTY_FIVE]: '５５人',
  [NumberOfPeople.FIFTY_SIX]: '５６人',
  [NumberOfPeople.FIFTY_SEVEN]: '５７人',
  [NumberOfPeople.FIFTY_EIGHT]: '５８人',
  [NumberOfPeople.FIFTY_NINE]: '５９人',
  [NumberOfPeople.SIXTY]: '６０人',
  [NumberOfPeople.SIXTY_ONE]: '６１人',
  [NumberOfPeople.SIXTY_TWO]: '６２人',
  [NumberOfPeople.SIXTY_THREE]: '６３人',
  [NumberOfPeople.SIXTY_FOUR]: '６４人',
  [NumberOfPeople.SIXTY_FIVE]: '６５人',
  [NumberOfPeople.SIXTY_SIX]: '６６人',
  [NumberOfPeople.SIXTY_SEVEN]: '６７人',
  [NumberOfPeople.SIXTY_EIGHT]: '６８人',
  [NumberOfPeople.SIXTY_NINE]: '６９人',
  [NumberOfPeople.SEVENTY]: '７０人',
  [NumberOfPeople.SEVENTY_ONE]: '７１人',
  [NumberOfPeople.SEVENTY_TWO]: '７２人',
  [NumberOfPeople.SEVENTY_THREE]: '７３人',
  [NumberOfPeople.SEVENTY_FOUR]: '７４人',
  [NumberOfPeople.SEVENTY_FIVE]: '７５人',
  [NumberOfPeople.SEVENTY_SIX]: '７６人',
  [NumberOfPeople.SEVENTY_SEVEN]: '７７人',
  [NumberOfPeople.SEVENTY_EIGHT]: '７８人',
  [NumberOfPeople.SEVENTY_NINE]: '７９人',
  [NumberOfPeople.EIGHTY]: '８０人',
  [NumberOfPeople.EIGHTY_ONE]: '８１人',
  [NumberOfPeople.EIGHTY_TWO]: '８２人',
  [NumberOfPeople.EIGHTY_THREE]: '８３人',
  [NumberOfPeople.EIGHTY_FOUR]: '８４人',
  [NumberOfPeople.EIGHTY_FIVE]: '８５人',
  [NumberOfPeople.EIGHTY_SIX]: '８６人',
  [NumberOfPeople.EIGHTY_SEVEN]: '８７人',
  [NumberOfPeople.EIGHTY_EIGHT]: '８８人',
  [NumberOfPeople.EIGHTY_NINE]: '８９人',
  [NumberOfPeople.NINETY]: '９０人',
  [NumberOfPeople.NINETY_ONE]: '９１人',
  [NumberOfPeople.NINETY_TWO]: '９２人',
  [NumberOfPeople.NINETY_THREE]: '９３人',
  [NumberOfPeople.NINETY_FOUR]: '９４人',
  [NumberOfPeople.NINETY_FIVE]: '９５人',
  [NumberOfPeople.NINETY_SIX]: '９６人',
  [NumberOfPeople.NINETY_SEVEN]: '９７人',
  [NumberOfPeople.NINETY_EIGHT]: '９８人',
  [NumberOfPeople.NINETY_NINE]: '９９人',
  [NumberOfPeople.ONE_HUNDRED]: '１００人'
};

export const EDITER_TYPE_LABELS: Labels = {
  [EditorTabEnum.EDIT]: '編集',
  [EditorTabEnum.PREVIEW]: 'プレビュー'
};
