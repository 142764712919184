export enum Hour {
  ONE = '1',
  ONE_AND_A_HALF = '1.5',
  TWO = '2',
  TWO_AND_A_HALF = '2.5',
  THREE = '3',
  THREE_AND_A_HALF = '3.5',
  FOUR = '4',
  FOUR_AND_A_HALF = '4.5',
  FIVE = '5',
  FIVE_AND_A_HALF = '5.5',
  SIX = '6',
  SIX_AND_A_HALF = '6.5',
  SEVEN = '7',
  SEVEN_AND_A_HALF = '7.5',
  EIGHT = '8',
  EIGHT_AND_A_HALF = '8.5',
  NINE = '9',
  NINE_AND_A_HALF = '9.5',
  TEN = '10',
  TEN_AND_A_HALF = '10.5',
  ELEVEN = '11',
  ELEVEN_AND_A_HALF = '11.5',
  TWELVE = '12'
}

export enum NumberOfPeople {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
  TEN = '10',
  ELEVEN = '11',
  TWELVE = '12',
  THIRTEEN = '13',
  FOURTEEN = '14',
  FIFTEEN = '15',
  SIXTEEN = '16',
  SEVENTEEN = '17',
  EIGHTEEN = '18',
  NINETEEN = '19',
  TWENTY = '20',
  TWENTY_ONE = '21',
  TWENTY_TWO = '22',
  TWENTY_THREE = '23',
  TWENTY_FOUR = '24',
  TWENTY_FIVE = '25',
  TWENTY_SIX = '26',
  TWENTY_SEVEN = '27',
  TWENTY_EIGHT = '28',
  TWENTY_NINE = '29',
  THIRTY = '30',
  THIRTY_ONE = '31',
  THIRTY_TWO = '32',
  THIRTY_THREE = '33',
  THIRTY_FOUR = '34',
  THIRTY_FIVE = '35',
  THIRTY_SIX = '36',
  THIRTY_SEVEN = '37',
  THIRTY_EIGHT = '38',
  THIRTY_NINE = '39',
  FORTY = '40',
  FORTY_ONE = '41',
  FORTY_TWO = '42',
  FORTY_THREE = '43',
  FORTY_FOUR = '44',
  FORTY_FIVE = '45',
  FORTY_SIX = '46',
  FORTY_SEVEN = '47',
  FORTY_EIGHT = '48',
  FORTY_NINE = '49',
  FIFTY = '50',
  FIFTY_ONE = '51',
  FIFTY_TWO = '52',
  FIFTY_THREE = '53',
  FIFTY_FOUR = '54',
  FIFTY_FIVE = '55',
  FIFTY_SIX = '56',
  FIFTY_SEVEN = '57',
  FIFTY_EIGHT = '58',
  FIFTY_NINE = '59',
  SIXTY = '60',
  SIXTY_ONE = '61',
  SIXTY_TWO = '62',
  SIXTY_THREE = '63',
  SIXTY_FOUR = '64',
  SIXTY_FIVE = '65',
  SIXTY_SIX = '66',
  SIXTY_SEVEN = '67',
  SIXTY_EIGHT = '68',
  SIXTY_NINE = '69',
  SEVENTY = '70',
  SEVENTY_ONE = '71',
  SEVENTY_TWO = '72',
  SEVENTY_THREE = '73',
  SEVENTY_FOUR = '74',
  SEVENTY_FIVE = '75',
  SEVENTY_SIX = '76',
  SEVENTY_SEVEN = '77',
  SEVENTY_EIGHT = '78',
  SEVENTY_NINE = '79',
  EIGHTY = '80',
  EIGHTY_ONE = '81',
  EIGHTY_TWO = '82',
  EIGHTY_THREE = '83',
  EIGHTY_FOUR = '84',
  EIGHTY_FIVE = '85',
  EIGHTY_SIX = '86',
  EIGHTY_SEVEN = '87',
  EIGHTY_EIGHT = '88',
  EIGHTY_NINE = '89',
  NINETY = '90',
  NINETY_ONE = '91',
  NINETY_TWO = '92',
  NINETY_THREE = '93',
  NINETY_FOUR = '94',
  NINETY_FIVE = '95',
  NINETY_SIX = '96',
  NINETY_SEVEN = '97',
  NINETY_EIGHT = '98',
  NINETY_NINE = '99',
  ONE_HUNDRED = '100'
}

export enum EditorTabEnum {
  EDIT = 0,
  PREVIEW = 1
}

export enum ImageCategory {
  USER_PROFILE = 'user_profile',
  USER_FACE = 'user_face',
  EVENT = 'event',
  QUESTIONNAIRE = 'questionnaire',
  CONFERENCE = 'conference'
}
